import { create } from 'zustand'
import apiHelper from '@/network/api'
import Cookies from 'js-cookie'

const useMemberStore = create((set, get) => ({
    member: null,
    customerPortalInfo: null,
    setMember: (newValue) => set({ member: newValue }),
    logout: () => {
        Cookies.remove('access_token')
    },

    fetchMember: async () => {
        if (Cookies.get('access_token') == null) {
            return
        }
        const response = await apiHelper(null).get('me')
        const data = response.data
        if (data) {
            set({ member: data })
            return data 
        }
    },

    getCustomerPortalLink: async () => {
        if (Cookies.get('access_token') == null) {
            return
        }
        const response = await apiHelper(null).get('me/customerPortal')
        const data = response.data
        if (data) {
            set({ customerPortalInfo: data })
            return data 
        }
    },

    checkIsLoggedIn: () => {
        return Cookies.get('access_token') != null
    },

    checkIsVip: () => {
        const member = get().member
        if (member) { 
            return member['isVip']
        }
        return false 
    },
}))

export default useMemberStore
