import {
    Box, Link, Center, HStack, Button, Spacer, Menu, MenuButton, MenuList, MenuItem, MenuDivider
} from "@chakra-ui/react"
import { useEffect } from "react"
import { ChevronDownIcon } from '@chakra-ui/icons'
import { useRouter } from "next/router"
import { useState } from "react"
import useMemberStore from "@/store/MemberStore"
import dynamic from 'next/dynamic'
import * as amplitude from "@amplitude/analytics-browser"

const lang = require('@/messages/HeaderComponent.json')
const _ = require('lodash')
const DynamicMenu = dynamic(() => import('@chakra-ui/react').then(mod => mod.Menu), { ssr: false })

export default function GlobalHeaderComponent({ locale }) {
    const t = lang[locale]
    const { fetchMember, getCustomerPortalLink, member, customerPortalInfo } = useMemberStore()
    const isLoggedIn = useMemberStore((state) => state.checkIsLoggedIn())
    const logout = useMemberStore((state) => state.logout)
    const router = useRouter()
    const { asPath } = router
    const [currentLanguage, setCurrentLanguage] = useState("English")
    const demoAccount = locale == "zh-TW" ? "netflixtw" : "bmw"
    const contactUsLink = locale == "zh-TW" ? "https://www.instagram.com/threadslytics/" : "https://www.instagram.com/threadslytics.global/"

    if (member) {
        amplitude.setUserId(member.id)
    }

    const handleLogout = () => {
        logout()
        window.location.reload()
    }

    const handleCustomerPortalLink = () => {
        if (customerPortalInfo) {
            const link = customerPortalInfo['customerPortalLink']
            router.push(link)
        }
    }

    useEffect(() => {
        const currentDomain = window.location.hostname
        if (_.includes(currentDomain, "tw.")) {
            setCurrentLanguage("正體中文")
        }
        fetchMember()
        getCustomerPortalLink()
    }, [])

    const handlePrivacyPolicy = () => {
        const html = locale == "zh-TW" ? "privacyPolicy.html" : "privacyPolicyEn.html"
        router.push(`/${html}`)
    }

    const handleTaiwanButton = () => {
        const domain = process.env.NEXT_PUBLIC_WEB_DOMAIN_NAME
        const protocol = domain == "localhost:4999" ? "http" : "https"
        const url = `${protocol}://tw.${domain}${asPath}`
        window.location.href = url
    }

    const handleEnglishButton = () => {
        const domain = process.env.NEXT_PUBLIC_WEB_DOMAIN_NAME
        const protocol = domain == "localhost:4999" ? "http" : "https"
        const url = `${protocol}://${domain}${asPath}`
        window.location.href = url
    }

    const handleConnectThreads = () => {
        router.push("/connectThreads")
    }

    const handleInviteClicked = () => {
        router.push("/team/invite")
    }

    let accounts = []
    if (member && member.connectedThreadsAccounts) {
        accounts = member.connectedThreadsAccounts
    }

    return (
        <Box height="68px" background="#302F2F" width="100%" display="flex" alignItems="center">
            <Box position="absolute" left="0" zIndex={99}>
                <Link href="/" color="main.red" marginLeft="16px" fontSize="26px" fontWeight="bold">Threadslytics</Link>
            </Box>
            <Box position="absolute" left="0px" width="100%">
                <Center>
                    <HStack spacing="28px">
                        <Link href="/pricing" color="white" fontWeight="medium" fontSize="18px">
                            {t["Title"]["pricing"]}
                        </Link>

                        <Link href={contactUsLink} color="white" fontWeight="medium" fontSize="18px" isExternal>
                            {t["Title"]["contactUs"]}
                        </Link>
                    </HStack>
                </Center>
            </Box>
            <Spacer />
            <DynamicMenu>
                <Menu>
                    <MenuButton
                        color="#fff"
                        _active={{ color: '#fff' }}
                        as={Button}
                        marginRight="16px"
                        variant='link'
                        rightIcon={<ChevronDownIcon />}>
                        {currentLanguage}
                    </MenuButton>
                    <MenuList>
                        <MenuItem onClick={handleTaiwanButton}>
                            正體中文
                        </MenuItem>
                        <MenuItem onClick={handleEnglishButton}>
                            English
                        </MenuItem>
                    </MenuList>
                </Menu>
            </DynamicMenu>
            {
                isLoggedIn &&
                <DynamicMenu>
                    <Menu>
                        <MenuButton
                            color="#fff"
                            _active={{ color: '#fff' }}
                            as={Button}
                            marginRight="16px"
                            variant='link'
                            rightIcon={<ChevronDownIcon />}>
                            {t["Title"]["accountManage"]}
                        </MenuButton>
                        <MenuList>
                            {accounts.map((o, index) => (
                                <MenuItem key={index} onClick={() => (
                                    router.push(`/dashboard/${o.username}`)
                                )}>
                                    {`${o.username}`} {t["Title"]["dashboard"]}
                                </MenuItem>
                            ))}
                            <MenuItem onClick={handleConnectThreads}>
                                {t["Title"]["connectThreads"]}
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem onClick={handleInviteClicked}>
                                {t["Title"]["inviteMembers"]}
                            </MenuItem>
                            <MenuDivider />
                            {customerPortalInfo && <MenuItem onClick={handleCustomerPortalLink}>
                                {t["Title"]["customerPortal"]}
                            </MenuItem>}
                            <MenuItem onClick={handlePrivacyPolicy}>
                                {t["Title"]["privacyPolicy"]}
                            </MenuItem>
                            <MenuItem onClick={handleLogout}>
                                {t["Title"]["logout"]}
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </DynamicMenu>
            }
        </Box >
    )
}