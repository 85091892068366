import "@/styles/globals.css"
import type { AppProps } from "next/app"
import theme from "../styles/theme"
import GlobalHeaderComponent from "../components/GlobalHeaderComponent"
import MobileGlobalHeaderComponent from "../components/MobileGlobalHeaderComponent"
import * as amplitude from "@amplitude/analytics-browser"
import { ChakraProvider, Box } from "@chakra-ui/react"
import { useMediaQuery } from "@chakra-ui/react"
import Script from "next/script"
import { NextIntlClientProvider } from "next-intl"
import { useRouter } from "next/router"
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Alert,
  AlertIcon,
} from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useRef } from "react"
import useMemberStore from "@/store/MemberStore"

const lang = require("@/messages/App.json")

export default function App({ Component, pageProps }: AppProps) {
  const [isDesktop] = useMediaQuery("(min-width: 800px)")
  const [isMobile] = useMediaQuery("(max-width: 480px)")
  const [isAlertShown, setIsAlertShown] = useState(false)
  const cancelRef = useRef()
  const maxWidth = isDesktop ? "1100px" : "100%"
  const minWidth = isDesktop ? "600px" : "100%"
  const GAID = process.env.NEXT_PUBLIC_GA_ID
  const router = useRouter()
  const { member } = useMemberStore()
  const { locale } = router
  const { pathname } = router
  const t = lang[locale]

  const header = isMobile ? (
    <MobileGlobalHeaderComponent locale={router.locale} />
  ) : (
    <GlobalHeaderComponent locale={router.locale} />
  )
  amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_ID, null, {
    minIdLength: 1,
  })

  const handleConnectButton = () => {
    router.push("/connectThreads")
  }

  useEffect(() => {
    if (member) {
      const accounts = member.connectedThreadsAccounts
      const id = member.id
      setIsAlertShown(
        accounts.length == 0 &&
          pathname != "/connectThreads" &&
          pathname != "/threads/callback" &&
          id <= 578 // not for new users
      )
    }
  }, [member, pathname])

  return (
    <NextIntlClientProvider
      locale={router.locale}
      timeZone="Asia/Taipei"
      messages={pageProps.messages}>
      <ChakraProvider theme={theme}>
        <Script
          src={`https://www.googletagmanager.com/gtag/js?id=G-${GAID}`}
          strategy="afterInteractive"
        />
        <Script id="google-analytics" strategy="afterInteractive">
          {`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-${GAID}');
        `}
        </Script>

        {header}
        <Box
          bg="ui.foreground1"
          maxWidth={maxWidth}
          minWidth={minWidth}
          marginLeft={"auto"}
          marginRight={"auto"}>
          <Component {...pageProps} />
          <AlertDialog isOpen={isAlertShown} leastDestructiveRef={cancelRef}>
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  <Alert status="info" variant="subtle">
                    <AlertIcon />
                    {t["Title"]["connectAccount"]}
                  </Alert>
                </AlertDialogHeader>
                <AlertDialogBody>
                  {t["Message"]["connectAccount"]}
                  <br />
                  {t["Message"]["connectAccount1"]}
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button
                    ref={cancelRef}
                    onClick={handleConnectButton}
                    colorScheme="blue">
                    {t["Action"]["learnMore"]}
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </Box>
      </ChakraProvider>
    </NextIntlClientProvider>
  )
}
